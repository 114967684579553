import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';



import React from 'react';

//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";


import './index.css';

import App1 from './App1';

//import Widget from './Widget';
//import Widgets from './Widgets';

import reportWebVitals from './reportWebVitals';

//import 'bootstrap/dist/css/bootstrap.min.css';









const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App1 />
  </React.StrictMode>
);



//ReactDOM.render(
  //<React.StrictMode>
    //<App1 />
  //</React.StrictMode>,
  //document.getElementById('root')
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
