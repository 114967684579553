import Web3 from 'web3';
import React from 'react';

//import AbiFactoryPolygon from "./contracts/MyFactoryPolygon.json";
//import AbiFactoryBSC from "./contracts/MyFactoryBSC.json";
//import AbiMyToken from "./contracts/Mytoken.json";
import { useState, useEffect } from "react";
import './App1.css';

import ethereum from './ethereum.svg';
import binance from './binance.svg';
//import update from './update.svg';
//import search from './search.svg';
import gear from './gear.png';
//import copy from './copy.svg';
import mecenas from './mecenas.svg';
import matic from './matic.svg';
//import balls from './balls1.png';
//import jackpotback from './JACKPOTBACK.png';
import cream from './cream.svg';
import tether from './tether.svg';
import dai from './dai.svg';
import usdc from './usdc.svg';
import tickets from './tickets.png';
import pricetag from './pricetag.png';
import award from './award.png';
import farm from './farm.png';
import winner from './winner.png';
import partner from './partner.png';
import gitbook from './gitbook.svg';
import blocks from './blocks.png';
import giving1 from './giving1.png';
import aviso from './aviso.png';
import multisign from './multisign.png';
import mecenas1 from './logomecenas-bgr.png';
import chainlink from './chainlink-rbg.png';
import yieldsource from './yieldsource-rbg1.png';
import mecenas2 from './logobeat1.gif';
import audit from './AuditReport.png';
import telegram from './telegram.png';
import twitter from './twitter.svg';
import videomecenas from './MECENASVIDEO.mp4';
import videomecenasesp from './MECENASVIDEOESP.mp4';
import harmony from './harmony.svg';
import portada1 from './portadaeng.png';
import portada2 from './portadaesp.png';
import github from './github.png';

import seconds from './15SECONDS.png';
import secondsspa from './15SECONDSSPA.png';
import global from './GLOBAL.png';
import globalspa from './GLOBALSPA.png';
import transparency from './TRANSPARENCY.png';
import transparencyspa from './TRANSPARENCYSPA.png';

import protocol from './PROTOCOL.png';
import protocolspa from './PROTOCOLSPA.png';
import pot from './POT.png';
import potspa from './POTSPA.png';

import flagspanish from './flagspanish.png';
import flagenglish from './flagenglish.png';
import dorahacks from './dorahacks.png';
//import logonuevo from './logonuevo1.gif';
//import logonuevo from './patronlogo.png';
import corazon from './corazon.png';
import mundo from './LANDIMAGE.gif';
//import logonuevo from './cryptologo.png';
import language from './language.png';

import logonuevo from './patronlogotrans.png';

import {
   Button,
   Input,
} from 'reactstrap';




function App1() {


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  const thelang = urlParams.get("lang");




const [thepage, setpage] = useState(0);





    useEffect(() => {
      
      if (thelang == 1) {
        setpage(1);
      } else {
  
        if (thelang == 2) {
          setpage(2);
        } else {
  
          setpage(1);
        } 
      }  

       
    
     
  },[]);




const HandleSetEnglish = () => {
setpage(1);
}

const HandleSetSpanish = () => {
  setpage(2);
}











  return (

    
<div className="App1">    
<div class="container">

<p></p>


<nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
  <div class="container-fluid">
  <a class="navbar-brand"><img src={logonuevo} class="img-fluid" width="60" height="auto"></img></a>
    <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">


<li class="nav-item">

{thepage == 1 &&    

<a class="nav-link" href="https://builder.cryptopatron.me/?lang=1" target="_blank" rel="noopener noreferrer">Builder</a>}


{thepage == 2 &&    

<a class="nav-link" href="https://builder.cryptopatron.me/?lang=2" target="_blank" rel="noopener noreferrer">Constructor</a>}

</li>




<li class="nav-item">

{thepage == 1 &&    

<a class="nav-link" href="https://app.cryptopatron.me/?pool=0x9F63eef4A69B8c308d37366C897A8C232Af9e66F&lang=1&desc=Here goes the description of your campaign." target="_blank" rel="noopener noreferrer">Demo</a>}


{thepage == 2 &&    

<a class="nav-link" href="https://app.cryptopatron.me/?pool=0x9F63eef4A69B8c308d37366C897A8C232Af9e66F&lang=2&desc=Descripcon de tu campaña." target="_blank" rel="noopener noreferrer">Demo</a>}

</li>





    


    <li class="nav-item">

    <a class="nav-link" href="https://docs.cryptopatron.me/protocol/crypto-patron" target="_blank" rel="noopener noreferrer">Docs</a>

    </li>












        



<li class="nav-item">
{thepage == 1 &&
<a class="nav-link" onClick={HandleSetSpanish}><div class="pointer">Español</div></a>}

{thepage == 2 &&
<a class="nav-link" onClick={HandleSetEnglish}><div class="pointer">English</div></a>}
</li>


      </ul>
    </div>
  </div>
</nav>


<p></p>
<br></br>
<br></br>
<br></br>
<br></br>





<div class="row row-cols-1 row-cols-lg-2 g-1">
  <div class="col">
    <div class="card bg-transparent h-100">
    <div class="card-body bg-transparent">



{thepage == 1 &&
<h1><p class="text1 text-start"><b>Lend your Principal. Give the Yield. Take the Pot.</b></p></h1>}


{thepage == 2 &&
<h1><p class="text1 text-start"><b>Presta tu Capital. Dona los Intereses. Llevate el Bote.</b></p></h1>}



<p></p>




      
{thepage == 1 &&
<p class="text text-start text-dark"><b><small>
        Beyond the definition of patron ... "person who sponsors or protects artists and intellectuals" we extend it to any person or entity that requires a mean of fundraising & crowdfunding for a noble and honest cause.
        Build your pool in less than 15 seconds and begin fundraising.</small></b></p>}

{thepage == 2 &&
<p class="text text-start text-dark"><b><small>
Más allá de la definición de patrón... "persona que patrocina o protege a artistas e intelectuales" la extendemos a cualquier persona o entidad que requiera un medio de recaudación de fondos y crowdfunding para una causa noble y honesta.
Construye tu pool en menos de 15 segundos y comienza a recaudar.

</small></b></p>}

<br></br>


{thepage == 1 &&
<div class="text text-start">

<p></p>


<a href="https://builder.cryptopatron.me/?lang=1" target="_blank" rel="noopener noreferrer"><button class="button-36">Builder</button></a>


<span> </span>


<a href="https://app.cryptopatron.me/?pool=0x9F63eef4A69B8c308d37366C897A8C232Af9e66F&lang=1&desc=Here goes the description of your campaign." target="_blank" rel="noopener noreferrer"><button class="button-36">Demo</button></a>


</div>}


{thepage == 2 &&
<div class="text text-start">

<p></p>


<a href="https://builder.cryptopatron.me/?lang=2" target="_blank" rel="noopener noreferrer"><button class="button-36">Constructor</button></a>


<span> </span>


<a href="https://app.cryptopatron.me/?pool=0x9F63eef4A69B8c308d37366C897A8C232Af9e66F&lang=2&desc=Descripcon de tu campaña." target="_blank" rel="noopener noreferrer"><button class="button-36">Demo</button></a>



</div>}


<br></br>


<p class ="text text-start">











</p>




</div>
</div>
</div>


<div class="col">


<div class="card bg-transparent h-100">
<div class="card-body bg-transparent">


<div class="text text-center"><img src={mundo} class="img-fluid" width="400" height="auto"></img></div>



</div>
</div>

</div>







</div>



        <p class="text text-center text-muted"><small>cryptopatron.me - a Blockchain Protocol - Powered by AAVE Liquidity Protocol</small></p>
        
        
        <p class="text text-center text-muted"><a href="https://twitter.com/cryptopatronme" target="_blank" rel="noopener noreferrer"><button type="button" class="btn btn-lg btn-block bg-transparent"><img src={twitter} class="img-fluid" width="50" height="auto"></img></button></a></p>




</div>
</div>
      

        
        






);

}

export default App1;